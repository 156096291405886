.panel {
    border-radius: 8px;
    background-color: #f9f9f9; /* Light background color */
  }
  
  .panel-header {
    font-size: 3rem; /* Adjust header font size */
    color:#435ef7 ; /* Change header color */
  }
  
  .panel-content {
    font-size: 2rem; /* Adjust content font size */
    font-weight: 600;
    line-height: 2;
    color: #333; /* Change content color */
  }
  
  .ant-collapse-header {
    padding: 16px; /* Adjust padding for the header */
  }
  
  .ant-collapse-content {
    padding: 16px; /* Adjust padding for the content */
  }
  