@import url('https://fonts.googleapis.com/css2?family=K2D:wght@100;200;300;400;500;600;700;800&family=Macondo&family=Poppins:wght@200;300;400;500;600;700;800&family=Sacramento&family=Tsukimi+Rounded:wght@300;400;500;600;700&family=Varela+Round&display=swap');


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    font-family: "Poppins", sans-serif !important;
    position: relative;
}
section{
    padding: 2rem !important;
  }
.terms-services{
    padding: 3rem 9%!important;

}
  .faq-criteria{
    padding: 0rem 20rem !important;
    background: linear-gradient(to right, #f7f9fa, #e1e5e8);
  }

.hr {
    border-bottom: 3px solid #435ef7;
    width: 113px;
    margin: 0 auto;
}
.hr:hover{
    border-bottom: 3px solid #f26722 !important;
    width: 115px !important;
    margin: 0 auto;
}

h1 {
    font-size: 5rem !important;
    font-weight: 400 !important;
    color: #435ef7 !important;
}

h4 {
    font-size: 3.8rem !important;
    font-weight: 600 !important;
    color: #ffffff !important;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.all-devices {
    font-size: 1.8rem !important;
    font-weight: 600  !important;
    color: #ffffff !important; 
}
.for-responsive{
    display: none;
}

h2 {
    font-size: 2.9rem !important;
    font-weight: 400 !important;
    color: #435ef7 !important;
}

h3 {
    font-size: 2.4rem !important;
    font-weight: 400 !important;
    color: #333 !important;
}
h6 {
    font-size: 3rem !important;
    font-weight: 600 !important;
    color: #f26722 !important;
}
p {
    font-size: 2rem ;
    font-weight: 400 ;
    color: #333 ;
}

ul li{
    font-size: 1.8rem ;
    font-weight: 400 ;
    color: #333 ; 
}


button {
    --color: #435ef5;
    font-family: inherit;
    display: inline-block;
    width: 10em;
    height: 2.6em;
    line-height: 2.5em;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    margin-top: 30px !important;
    border: 2px solid #435ef5;
    transition: color 0.5s;
    z-index: 1;
    font-size: 22px;
    border-radius: 8px;
    font-weight: 500;
    color: var(--color);
  }
  
  button:before {
    content: "";
    position: absolute;
    z-index: -1;
    background:#435ef5;
    height: 150px;
    width: 200px;
    border-radius: 50%;
  }
  
  button:hover {
    color: #fff;
  }
  
  button:before {
    top: 100%;
    left: 100%;
    transition: all 0.7s;
  }
  
  button:hover:before {
    top: -30px;
    left: -30px;
  }
  
  button:active:before {
    background: #435ef5;
    transition: background 0s;
  }
.mt-50{
    margin-top: 50px !important;
}
.mt-100{
    margin-top: 110px !important;
}

.line-height {
    line-height: 4.5rem;
}
.quote-card{
    background-color: #0f0c29;
}
.service-stages{
    width: 400px;
    height: 400px;
    border: 5px solid #afafaf;
    margin: 0 auto !important;
}
.study-stages{
    width: 200px;
    height: 200px;
    border: 5px solid #afafaf;
    border-radius: 50%;
    margin: 0 auto !important;
}
.metrics-box{
    border: 4px solid #dbe2e7;
    padding: 10px;
    width: 200px;
    height: 200px;
    border-radius: 10px;
    background-color: #dbe2e7;
    display: grid;
    justify-content: center;
    align-items: center;
}
.metrics-box:hover{
    border: 6px solid #f26722 !important;
    cursor: pointer !important;
}
.metric-section{
    padding: 7rem !important;
    background: #0f0c29;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #24243e, #302b63, #0f0c29); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    


}
.margint-50{
    margin-top: 50px !important;
}
.cardbg-1{
    background: url(./pages/images/cardbg.svg) bottom no-repeat #E6EBFF;
    background-size: contain !important;
    padding: 20px 20px 60px 20px;
    min-height: 300px;
    width: 70%;
    border-radius: 20px;
    margin-bottom: 60px;
}
.cardbg-1 h2 span {
    float: right !important;
}
.cardbg-1 p {
    font-size: 1.6rem !important;
}
.cardbg-2{
    color: #fff !;
    background: url(./pages/images/card2bg.svg) bottom no-repeat #9641FF;
    background-size: contain !important;
    padding: 20px 20px 60px 20px;
    min-height: 300px;
    width: 70%;
    border-radius: 20px;
    margin-bottom: 60px;
}
.cardbg-2 h4 {
    font-size: 2.9rem !important;
    font-weight: 400 !important;
}
.cardbg-2 h4 span {
    float: right !important;
}
.cardbg-2 p {
    color: white !important;
    font-size: 1.6rem !important;
}
.d-mt {
    margin-top: -40px !important;
}
.reviewPage-section{
    padding: 9rem 12% !important;
}
.custom-dialog {
    padding: 1rem;
    border: 3px solid darkblue; /* Custom border */
    border-radius: 8px; /* Optional: adjust border radius */
    background-image: linear-gradient(to top, #fff1eb 0%, #d7eef8 100%);
  }
  
  .custom-backdrop {
    backdrop-filter: blur(8px); /* Blur effect */
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(100, 100, 100, 0.7)); /* Smoky gradient */
  }

  .invoice-dialogue {
    padding: 2rem;
    border-radius: 10px; /* Adjust border radius */
    background-image: linear-gradient(to top, #e8f1fa 0%, white 100%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}
.invoice-page{
    padding: 9rem 10% !important;
    background: #F0F2F0;  /* fallback for old browsers */
background: -webkit-linear-gradient(to right, #000C40, #F0F2F0);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to right, #b5bee7, #F0F2F0); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
}

  
@media (max-width: 480px) {
    .study-stages {
        width: 150px;
        height: 150px;
        border: 5px solid #afafaf;
        border-radius: 50%;
        margin: 0 auto; /* Centers the circle within the slide */
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; 
        
    }
    .margint-50{
        margin-top: -23px !important;
    }
    .booknow-section {
        background: url(/src/pages/booknowBackground.png) no-repeat center center;
        background-size: cover; /* Cover ensures the image covers the entire section */
        width: 100%;
        min-height: 856px !important;
        display: flex; /* Use flexbox to center content */
        align-items: center; /* Center vertically */
        justify-content: center; /* Center horizontally */
      }
    .responsive{
        flex-wrap: wrap-reverse !important;
    }
    .faq-criteria{
        padding: 2rem !important;
        background: linear-gradient(to right, #f7f9fa, #e1e5e8) !important;
      }
      .all-devices {
       display: none !important;
    }
    .for-responsive{
        display: block !important;
        margin-top: 15px !important;
        font-size: 1.6rem !important;
    font-weight: 600  !important;
    color: #ffffff !important; 
    line-height: 3 !important;
    }
    
  }
  .study-phases{
    width: 200px;
    height: 200px;
    border: 5px solid #f26722;
    border-radius: 50%;
    margin: 0 auto !important;
}
.service-section{
    padding: 4rem 13% !important;
}

@media (max-width: 480px) {
    .study-phases {
        width: 150px;
        height: 150px;
        border: 5px solid #f26722;
        border-radius: 50%;
        margin: 0 auto; /* Centers the circle within the slide */
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; 
    }

}
  .home-carousel{
    width: 78% !important;
    margin: 0 auto !important;
}


@media (max-width: 480px) {
    .cardbg-2 {
        width: 100% !important;
    }
    .cardbg-1 {
        width: 100% !important;
    }
    .cardbg-2 h4 {
        font-size: 1.9rem !important;
        font-weight: 400 !important;
    }
    .cardbg-1 h2 {
        font-size: 1.9rem !important;
        font-weight: 400 !important;
    }
    .home-carousel {
        width: 300px;
        height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center; 
    }
   
  }
.count-section {
  
  background: linear-gradient(to right, #f7f9fa, #f3f3f3);

}
.image-border {
    border-radius: 8px; /* Rounded corners */
    box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.1),   /* First shadow (light) */
        0 8px 16px rgba(0, 0, 0, 0.1),  /* Second shadow (medium) */
        0 12px 24px rgba(0, 0, 0, 0.2); /* Third shadow (darker) */
    transition: transform 0.3s, box-shadow 0.3s; /* Smooth transition for scale and shadow */
}

.image-border:hover {
    transform: scale(1.05); /* Slightly enlarge on hover */
    box-shadow: 
        0 6px 12px rgba(0, 0, 0, 0.15),  /* Enhanced shadow on hover */
        0 12px 24px rgba(0, 0, 0, 0.2),
        0 16px 32px rgba(0, 0, 0, 0.3); /* Deeper shadow on hover */
}
@media (max-width: 1500px) {
    .cardbg-2 h4 {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
    }
    .cardbg-1 h2 {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
    }
}
