.carousel-main {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

h1 {
  font-size: 5rem !important;
  font-weight: 600 !important;
  color: #435ef7 !important;
}

/* From Uiverse.io by aguerquin */
/* From Uiverse.io by cssbuttons-io */


.card-section{
  padding: 2rem !important;
}



.container {
  margin-top: 1.25em;
  margin-bottom: 1.375em;
  margin-left: 1.375em;
  margin-right: 2em;
  display: flex;
  flex-direction: row;
  gap: 0.75em;
}

.status-ind {
  width: 0.625em;
  height: 0.625em;
  background-color: #ff0000;
  margin: 0.375em 0;
  border-radius: 0.5em;
}

.text-wrap {
  display: flex;
  flex-direction: column;
  gap: 0.25em;
  color: #ff0000;
}

.time {
  font-size: 0.875em;
  color: #777;
}

.text-link {
  font-weight: 500;
  text-decoration: none;
  color: black;
}

.button-wrap {
  display: flex;
  flex-direction: row;
  gap: 1em;
  align-items: center;
}

.secondary-cta {
  background-color: transparent;
  border: none;
  font-size: 15px;
  font-weight: 400;
  color: #666;
  cursor: pointer;
}

.primary-cta {
  font-size: 15px;
  background-color: transparent;
  font-weight: 600;
  color: #1677ff;
  border: none;
  border-radius: 1.5em;
  cursor: pointer;
}

.right {
  display: flex;
  flex-direction: column;
  gap: 0.875em;
}

.footer {
  background-color: black;
}

.footer .box-container {
  padding: 4rem  14%;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 1.5rem;
}

.footer .box-container .box h3 {
  padding: .5rem 0;
  font-size: 2.5rem;
  color: #dcb658;
}

.footer .box-container .box a {
  display: block;
  padding: .5rem 0;
  font-size: 1.7rem;
  color: white;
}

.footer .box-container .box a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.footer .credit {
  text-align: center;
  border-top: .1rem solid rgba(0, 0, 0, .1);
  font-size: 2rem;
  color: #01796f;
  padding: .5rem;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.footer .credit span {
  color: #dcb658;
}
@media (max-width: 1500px) {
  .footer .box-container {
      padding: 4rem 10%;
      grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
}

@media (max-width: 1200px) {
  .footer .box-container {
      padding: 3rem 5%;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
}

@media (max-width: 1000px) {
  .footer .box-container {
      padding: 2rem 3%;
      grid-template-columns: 1fr; /* Stack boxes vertically */
  }

  .footer .box h3 {
      font-size: 2rem;
  }

  .footer .box a {
      font-size: 1.5rem;
  }

  .footer .credit {
      font-size: 1.5rem;
  }
}
