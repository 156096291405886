/* CustomLoader.css */
.loader {
    width: 88px; /* Increased size */
    height: 88px; /* Increased size */
    color: #435ef7;
    position: relative;
    background: radial-gradient(22px, currentColor 94%, #0000);
    margin: auto; /* Center horizontally */
}

.loader:before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: radial-gradient(20px at bottom right, #0000 94%, currentColor) top left,
                radial-gradient(20px at bottom left, #0000 94%, currentColor) top right,
                radial-gradient(20px at top right, #0000 94%, currentColor) bottom left,
                radial-gradient(20px at top left, #0000 94%, currentColor) bottom right;
    background-size: 44px 44px; /* Adjusted for increased size */
    background-repeat: no-repeat;
    animation: loader 1.5s infinite cubic-bezier(0.3, 1, 0, 1);
}

@keyframes loader {
    33% {
        inset: -22px; /* Adjusted for size */
        transform: rotate(0deg);
    }

    66% {
        inset: -22px; /* Adjusted for size */
        transform: rotate(90deg);
    }

    100% {
        inset: 0;
        transform: rotate(90deg);
    }
}
