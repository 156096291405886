


.carousel-main{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}
/* From Uiverse.io by aguerquin */ 
/* From Uiverse.io by cssbuttons-io */ 

  .card-section{
    padding: 2rem !important;
  }

  /* Card Selection */
  .mutliple-cards {
    width: fit-content;
    background-color: #f2f3f7;
    border-radius: 0.75em;
    cursor: pointer;
    transition: ease 0.2s;
    width: 93% !important;
    box-shadow: 1em 1em 1em #d8dae0b1, -0.75em -0.75em 1em #ffffff;
    border: 1.5px solid #f2f3f7;
  }
  
  .mutliple-cards:hover {
    background-color: #d3ddf1 !important;
    border: 1.5px solid #302b63 !important;
  }
  
  .container {
    margin-top: 1.25em;
    margin-bottom: 1.375em;
    margin-left: 1.375em;
    margin-right: 2em;
    display: flex;
    flex-direction: row;
    gap: 0.75em;
  }
  
  .status-ind {
    width: 0.625em;
    height: 0.625em;
    background-color: #333;
    margin: 0.375em 0;
    border-radius: 0.5em;
  }
  
  .text-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    color: #333;
  }
  
  .time {
    font-size: 0.875em;
    color: #777;
  }
  
  .text-link {
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  
  .button-wrap {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }
  
  .secondary-cta {
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
  }
  
  .primary-cta {
    font-size: 15px;
    background-color: transparent;
    font-weight: 600;
    color: #1677ff;
    border: none;
    border-radius: 1.5em;
    cursor: pointer;
  }
  
  .right {
    display: flex;
    flex-direction: column;
    gap: 0.875em;
  }
  .home-carousel {
    position: relative;
}

.overlay-image {
    position: absolute;
    top: 14%; /* Adjust positioning as needed */
    left: -12%; /* Adjust positioning as needed */
    width: 48%; /* Adjust size as needed */
    height: auto; /* Maintain aspect ratio */
    opacity: 0; /* Initially hide the image */
    transition: opacity 0.3s ease; /* Smooth transition for appearance */
}

.home-carousel:hover .overlay-image {
    opacity: 1; /* Show the image on hover */
}
  
.overlay-image2 {
  position: absolute;
  top: 13%;
    left: 65%;/* Adjust positioning as needed */
  width: 48%; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  opacity: 0; /* Initially hide the image */
    transition: opacity 0.3s ease; /* Smooth transition for appearance */
}
.home-carousel:hover .overlay-image2 {
  opacity: 1; /* Show the image on hover */
}
@media (max-width: 480px) {
  .overlay-image {
    position: absolute;
    top: 31%;
    left: -12%;
    width: 48%;
    height: auto;
    opacity: 0; /* Initially hide the image */
    transition: opacity 0.3s ease; /* Smooth transition for appearance */
}
.overlay-image2 {
  position: absolute;
  top: 31%;
    left: 65%;/* Adjust positioning as needed */
  width: 48%; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  opacity: 0; /* Initially hide the image */
  transition: opacity 0.3s ease; /* Smooth transition for appearance */
}
.home-carousel:hover .overlay-image {
  opacity: 1; /* Show the image on hover */
}
.home-carousel:hover .overlay-image2 {
  opacity: 1; /* Show the image on hover */
}
h1{
  font-size: 3rem !important;
    font-weight: 600 !important;
    color: #435ef7 !important;
}
}

.engine-coil {
  position: absolute;
  top: 54%;
  left: 45%;
  width: 10%;
  height: auto;
  animation: rotate 3s linear infinite;
}

/* Define the keyframes for the rotation animation */
@keyframes rotate {
  from {
    transform: rotate(0deg); /* Start at 0 degrees */
  }
  to {
    transform: rotate(360deg); /* Rotate to 360 degrees */
  }
}



  
   .car-section{
    background: url(../stapleFordBackground.png);
    background-repeat: no-repeat !important;
    background-position: 39% 29% !important;
    background-size: 80% !important;
}
.booknow-section {
  background: url(../booknowBackground.png) no-repeat center center;
  background-size: cover; /* Cover ensures the image covers the entire section */
  width: 100%;
  height: 650px;
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.text-container {
  text-align: center; /* Center the text within the container */
}
/* .booknow-section{
  background: url(../booknowBackground.png);
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  width: 100%;
  height: 580px;
} */
p {
  font-size: 2rem !important;
  font-weight: 400 !important;
  color: #333 !important;
}
.hr {
  font-size: 3.2rem !important;
  font-weight: 600 !important;
  color: #302b63 !important; 
  width: 110px;
}