
.nav-link-image {
  background: url(../navimage.png) no-repeat center center;
  background-size: cover; /* Cover ensures the image covers the entire section */
  width: 100%;
  height: 300px;
  display: flex; /* Use flexbox to center content */
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
}

.text-container {
  text-align: center; /* Center the text within the container */
}

  .card-section{
    padding: 2rem !important;
  }

  /* Card Selection */
  .mutliple-cards {
    width: fit-content;
    background-color: #f2f3f7;
    border-radius: 0.75em;
    cursor: pointer;
    transition: ease 0.2s;
    box-shadow: 1em 1em 1em #d8dae0b1, -0.75em -0.75em 1em #ffffff;
    border: 1.5px solid #f2f3f7;
  }
  
  .mutliple-cards:hover {
    background-color: #d3ddf1 !important;
    border: 1.5px solid #1677ff !important;
  }
  
  .container {
    margin-top: 1.25em;
    margin-bottom: 1.375em;
    margin-left: 1.375em;
    margin-right: 2em;
    display: flex;
    flex-direction: row;
    gap: 0.75em;
  }
  
  .status-ind {
    width: 0.625em;
    height: 0.625em;
    background-color: #333;
    margin: 0.375em 0;
    border-radius: 0.5em;
  }
  
  .text-wrap {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    color: #333;
  }
  
  .time {
    font-size: 0.875em;
    color: #777;
  }
  
  .text-link {
    font-weight: 500;
    text-decoration: none;
    color: black;
  }
  
  .button-wrap {
    display: flex;
    flex-direction: row;
    gap: 1em;
    align-items: center;
  }
  
  .secondary-cta {
    background-color: transparent;
    border: none;
    font-size: 15px;
    font-weight: 400;
    color: #666;
    cursor: pointer;
  }
  
  .primary-cta {
    font-size: 15px;
    background-color: transparent;
    font-weight: 600;
    color: #1677ff;
    border: none;
    border-radius: 1.5em;
    cursor: pointer;
  }
  
  .right {
    display: flex;
    flex-direction: column;
    gap: 0.875em;
  }
  .count-section{
    background-color: #e7edff;
  }
  section{
    padding: 2rem !important;
  }
   
  