/* StapleFordNav.css */

.ant-menu-item {
    background: transparent !important; /* Remove default background */
  }
  
  .ant-menu-item a {
    text-decoration: none !important; /* Ensure links inside menu items have no underline */
    color: black; /* Ensure text color inherits from parent */
  }
  
  .ant-menu-item:hover {
    color: #435ef7 !important; /* Change color on hover */
    background-color: rgba(67, 94, 247, 0.1); /* Light background */
    transform: scale(1.05); /* Slightly scale up */
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); /* Add subtle text shadow */
    cursor: pointer; /* Change cursor to pointer */
}
  .menu-item {
    font-size: 2.2rem !important;
    font-weight: 600;
    color: black !important; /* Default color */
    transition: color 0.3s ease;
    text-decoration: none; /* Remove underline from menu items */
  }
  
 
  .menu-item.active {
    color: #435ef7 !important; /* Active item color */
  }
  
  .logo img {
    display: block; /* Ensure there's no extra spacing */
    border: none; /* Remove any potential borders */
  }
  